import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { getRouteApi, Link } from '@tanstack/react-router'

import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthResetPassword } from '@/features/auth/mutations'

import { LoadingButton } from '@/components/buttons/loading-button'
import { PasswordInput } from '@/components/common/password-input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'

import { schema } from './validation'

const Route = getRouteApi('/_auth')

type ResetPasswordFormProps = {
	onSuccess: () => void
}
export const ResetPasswordForm = ({ onSuccess }: ResetPasswordFormProps) => {
	const [isLoading, setIsLoading] = useState(false)

	const { email, code } = Route.useSearch({
		select: (search) => ({
			email: search.email,
			code: search.code,
		}),
	})

	const { mutate } = useAuthResetPassword()

	const form = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
		mode: 'onBlur',
	})

	const onSubmit = form.handleSubmit(async (data) => {
		if (isLoading) return

		setIsLoading(true)

		const { password } = data

		mutate(
			{
				confirmationCode: code as string,
				newPassword: password,
				username: email as string,
			},
			{
				onSuccess: () => {
					onSuccess()
				},
				onError: (error) => {
					form.setError('confirmPassword', {
						type: 'custom',
						message: error.message,
					})
				},
				onSettled: () => {
					setIsLoading(false)
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-center justify-center"
			>
				<PasswordInput form={form} name="password" label="New Password" />
				<PasswordInput
					form={form}
					name="confirmPassword"
					label="Confirm Password"
					isConfirm
				/>

				<LoadingButton
					isLoading={isLoading}
					data-testid="form-button"
					type="submit"
					variant="outline"
					className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
				>
					Reset Password
				</LoadingButton>

				<Button
					variant="ghost"
					type="button"
					className="mt-2 w-full rounded-md bg-slate-600 bg-opacity-100 px-6 py-3 text-white transition-all duration-200 hover:bg-opacity-60"
					asChild
				>
					<Link to="/confirm-code" search={{ email }}>
						Back
					</Link>
				</Button>
			</form>
		</Form>
	)
}
