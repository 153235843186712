import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Link } from '@tanstack/react-router'

import { cn } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthOTP } from '@/features/auth/mutations'

import { LoadingButton } from '@/components/buttons/loading-button'
import { Button } from '@/components/ui/button'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '@/components/ui/input-otp'

import { schema } from './validation'

type OTPFormProps = {
	onSuccess: (isSignedIn?: boolean) => void
}

export const OTPForm = ({ onSuccess }: OTPFormProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const { mutate } = useAuthOTP()

	const form = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			code: '',
		},
	})

	const onSubmit = form.handleSubmit(async (data) => {
		if (isLoading) return

		setIsLoading(true)

		mutate(
			{
				code: data.code,
			},
			{
				onSuccess: (data) => {
					onSuccess(data?.isSignedIn)
				},
				onError: (error) => {
					// Only remove loading when there is an error
					setIsLoading(false)

					if (error.name.includes('UserLambdaValidationException')) {
						form.setError('code', {
							type: 'custom',
							message:
								'Error validating authentication. Please contact support.',
						})
					} else {
						form.setError('code', {
							type: 'custom',
							message: error.message,
						})
					}
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-center justify-center gap-6"
			>
				<FormField
					control={form.control}
					name="code"
					render={({ field }) => (
						<FormItem className="flex w-full flex-col items-center justify-center">
							<FormControl>
								<InputOTP
									maxLength={6}
									{...field}
									data-testid="code"
									className="w-full"
									autoFocus
								>
									<InputOTPGroup>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={0}
										/>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={1}
										/>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={2}
										/>
									</InputOTPGroup>
									<InputOTPSeparator />
									<InputOTPGroup>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={3}
										/>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={4}
										/>
										<InputOTPSlot
											className={cn({
												'border-destructive': form.formState.errors?.code,
											})}
											index={5}
										/>
									</InputOTPGroup>
								</InputOTP>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="w-full">
					<LoadingButton
						isLoading={isLoading}
						data-testid="form-button"
						type="submit"
						variant="outline"
						className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
					>
						Login
					</LoadingButton>

					<Button
						variant="ghost"
						type="button"
						className="mt-2 w-full rounded-md bg-slate-600 bg-opacity-100 px-6 py-3 text-white transition-all duration-200 hover:bg-opacity-60"
						asChild
					>
						<Link to="/login">Back</Link>
					</Button>
				</div>
			</form>
		</Form>
	)
}
