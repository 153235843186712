import { Loader2 } from 'lucide-react'

import { Card, CardContent } from '@/components/ui/card'

export default function SkeletonWidget() {
	return (
		<Card className="m-0 border-none">
			<CardContent className="flex min-h-32 items-center justify-center space-y-2 py-4">
				<Loader2 className="h-10 w-10 animate-spin" />
			</CardContent>
		</Card>
	)
}
