type LogoProps = {
	className?: string
}

export const LogoWhite = ({ className }: LogoProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 22 32"
			className={className}
		>
			<path
				fill="currentColor"
				d="M14.624 3.986 7.376 0v15.33l7.248 3.986V3.986Z"
			/>
			<path
				fill="currentColor"
				d="m21.872 14.967-7.248 4.35v-8.155l7.248 3.805Z"
			/>
			<path
				fill="currentColor"
				d="M21.872 14.967v8.335l-7.248-3.986 7.248-4.349Z"
			/>
			<path
				fill="currentColor"
				d="m21.872 23.302-7.248-3.986L.128 28.014 7.376 32l14.496-8.698Z"
			/>
		</svg>
	)
}
