import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'

import { usePostHog } from 'posthog-js/react'
import { toast } from 'sonner'

import { sentry } from '@/lib/sentry'

import { actionsKeys } from '../actions/queries'
import { playbookKeys } from '../playbooks/queries'
import { useCurrentTenant } from '../user/store'
import { wizardKeys } from '../wizard/queries'

import {
	createIntegration,
	deleteIntegration,
	editIntegration,
	validateIntegration,
} from './api'
import { integrationKeys } from './queries'

import type { EditIntegrationFormData, NewIntegrationFormData } from './types'
import type { XiorError } from 'xior'

export const integrationMutations = {
	all: ['integrations'] as const,
	create: (tenant: string) =>
		[...integrationMutations.all, tenant, 'create'] as const,
	edit: (tenant: string) =>
		[...integrationMutations.all, tenant, 'edit'] as const,
	delete: (tenant: string) =>
		[...integrationMutations.all, tenant, 'delete'] as const,
	validate: (tenant: string) =>
		[...integrationMutations.all, tenant, 'validate'] as const,
}

/**
 * Create an integration
 */
export const useCreateIntegration = () => {
	const posthog = usePostHog()

	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	return useMutation({
		mutationKey: integrationMutations.create(tenant),
		mutationFn: (integration_data: NewIntegrationFormData) =>
			createIntegration(tenant, integration_data),
		onMutate: () => {
			toast.loading('Creating integration...', {
				id: 'create-integration',
			})
		},
		onError: (error, variables) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the definition_id
			sentry.captureException(message, {
				extra: {
					definition_id: variables.definition_id,
				},
			})

			toast.dismiss('create-integration')
			toast.error('Failed to create integration', {
				description: message,
			})
		},
		onSuccess: (data) => {
			posthog.capture('integration_created', data)

			toast.dismiss('create-integration')
			toast.success('Integration created successfully')

			navigate({
				to: `/integrations/$id`,
				params: {
					id: data?.id,
				},
			})
		},
		onSettled: () => {
			// Reset the available integrations list
			queryClient.invalidateQueries({
				queryKey: integrationKeys.available(tenant),
			})

			// Reset the tenant integrations list
			queryClient.invalidateQueries({
				queryKey: integrationKeys.lists(),
			})

			// Reset the Action list
			queryClient.invalidateQueries({
				queryKey: actionsKeys.lists(),
			})

			// Reset the Playbook list
			queryClient.invalidateQueries({
				queryKey: playbookKeys.lists(),
			})

			// Reset the onboarding in progress
			queryClient.invalidateQueries({
				queryKey: wizardKeys.onboardingInProgress(tenant),
			})
		},
	})
}

/**
 * Edit an integration
 */
export const useEditIntegration = () => {
	const posthog = usePostHog()
	const tenant = useCurrentTenant() as string
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: integrationMutations.edit(tenant),
		mutationFn: ({
			integration_id,
			integration_data,
		}: {
			integration_id: string
			integration_data: EditIntegrationFormData
		}) => editIntegration(tenant, integration_id, integration_data),
		onMutate: () => {
			toast.loading('Editing integration...', {
				id: 'edit-integration',
			})
		},
		onError: (error, variables) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the definition_id
			sentry.captureException(message, {
				extra: {
					integration_id: variables.integration_id,
				},
			})

			toast.dismiss('edit-integration')
			toast.error('Failed to edit integration', {
				description: message,
			})
		},
		onSuccess: (data) => {
			posthog.capture('integration_updated', data)

			toast.dismiss('edit-integration')
			toast.success('Integration updated successfully')
		},
		onSettled: (_d, _e, variables) => {
			queryClient.invalidateQueries({
				queryKey: integrationKeys.lists(),
			})

			queryClient.invalidateQueries({
				queryKey: integrationKeys.detail(tenant, variables.integration_id),
			})
		},
	})
}

/**
 * Delete an integration
 */
export const useDeleteIntegration = () => {
	const posthog = usePostHog()

	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	return useMutation({
		mutationKey: integrationMutations.delete(tenant),
		mutationFn: (integration_id: string) =>
			deleteIntegration(tenant, integration_id),
		onMutate: () => {
			toast.loading('Deleting integration...', {
				id: 'delete-integration',
			})
		},
		onError: (error, variables) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the definition_id
			sentry.captureException(message, {
				extra: {
					integration_id: variables,
				},
			})

			toast.dismiss('delete-integration')
			toast.error('Failed to delete integration', {
				description: message,
			})
		},
		onSuccess: (_data, variables) => {
			posthog.capture('integration_deleted', {
				integration_id: variables,
			})

			toast.dismiss('delete-integration')
			toast.success('Integration deleted successfully')

			navigate({
				to: '/integrations',
			})
		},
		onSettled: () => {
			// Reset the available integrations list
			queryClient.invalidateQueries({
				queryKey: integrationKeys.available(tenant),
			})

			// Reset the tenant integrations list
			queryClient.invalidateQueries({
				queryKey: integrationKeys.lists(),
			})

			// Reset the Action list
			queryClient.invalidateQueries({
				queryKey: actionsKeys.lists(),
			})

			// Reset the Playbook list
			queryClient.invalidateQueries({
				queryKey: playbookKeys.lists(),
			})

			// Reset the onboarding in progress
			queryClient.invalidateQueries({
				queryKey: wizardKeys.onboardingInProgress(tenant),
			})
		},
	})
}

/**
 * Validate an integration
 */
export const useValidateIntegration = () => {
	const tenant = useCurrentTenant() as string

	return useMutation({
		mutationKey: integrationMutations.validate(tenant),
		mutationFn: (integration_data: NewIntegrationFormData) =>
			validateIntegration(tenant, integration_data),
		onMutate: () => {
			toast.loading('Validating integration...', {
				id: 'validate-integration',
			})
		},
		onError: (error, variables) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the definition_id
			sentry.captureException(message, {
				extra: {
					definition_id: variables.definition_id,
				},
			})

			toast.dismiss('validate-integration')
			toast.error('Failed to validate integration', {
				description: message,
			})
		},
		onSuccess: () => {
			toast.dismiss('validate-integration')
			toast.success('Integration data is valid!')
		},
	})
}
