import { createFileRoute, redirect } from '@tanstack/react-router'

import { redirectHome } from '@/utils'

import { getCurrentSKU } from '@/features/user/store'

import type { TenantSKU } from '@/features/tenant/types'

export const Route = createFileRoute('/_dashboard/mdr/')({
	beforeLoad: async () => {
		const sku = getCurrentSKU() as TenantSKU

		// Only redirect if the SKU is MDR enabled
		if (sku && sku.is_mdr_enabled) {
			throw redirect({ to: '/mdr/executive-reports' })
		} else {
			redirectHome()
		}
	},
})
