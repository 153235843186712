import { lazy } from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { redirectHome } from '@/utils'
import { z } from 'zod'

import { AuthLayout } from '@/features/auth/components/layout'
import { isUserAuthenticated } from '@/features/auth/queries'

const ThemeToggle = lazy(() => import('@/components/common/theme-toggle'))

/**
 * Auth layout that wraps the entire authentication flow.
 */

const authSearchSchema = z.object({
	// Redirect URL when signed out
	redirect: z.string().optional(),
	// Email address to reset password
	email: z.string().optional(),
	// Confirmation code to reset password
	code: z.string().optional(),
	// Next step to redirect to, if any (from AWS Cognito)
	nextStep: z.string().optional(),
	// TOTP secret to setup
	totpSecret: z.string().optional(),
	// Shared secret to setup
	sharedSecret: z.string().optional(),
})

export type AuthSearch = z.infer<typeof authSearchSchema>

export const Route = createFileRoute('/_auth')({
	/**
	 * We check the user auth session before loading the auth layout.
	 * @see https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes
	 */
	validateSearch: zodValidator(authSearchSchema),

	beforeLoad: async ({ context: { queryClient }, location }) => {
		// If the user is logging out, don't check if they're authenticated
		if (location.pathname === '/logout') return

		// If user is not authenticated, ignore redirect
		const isAuthenticated = await isUserAuthenticated(queryClient)

		if (isAuthenticated) {
			redirectHome()
		}
	},

	component: AuthRoute,
})

function AuthRoute() {
	return (
		<AuthLayout>
			<Outlet />

			<div className="fixed right-4 top-4">
				<ThemeToggle />
			</div>
		</AuthLayout>
	)
}
