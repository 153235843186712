import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { Button } from '@/components/ui/button'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/ui/card'

export const ErrorWidget = () => {
	const { reset } = useErrorBoundary()

	return (
		<Card className="m-0 border-none">
			<CardHeader className="pt-0">
				<CardDescription>Unable to fetch data</CardDescription>
				<CardTitle>Error</CardTitle>
			</CardHeader>
			<CardContent>
				<p>Error fetching data</p>
				<Button variant="outline" type="button" onClick={() => reset()}>
					Try again
				</Button>
			</CardContent>
		</Card>
	)
}

export default ErrorWidget
