import { useNavigate } from '@tanstack/react-router'

import { SIGN_IN_STEPS } from '../types'

import type { RESET_PASSWORD_STEPS } from '../types'
import type { LinkProps } from '@tanstack/react-router'
import type { ResetPasswordOutput, SignInOutput } from 'aws-amplify/auth'

export type TotpSetupDetails = {
	signInStep: SIGN_IN_STEPS
	totpSetupDetails?: {
		getSetupUri: (provider: string) => URL
		sharedSecret: string
	}
}

export const useAuthNextSteps = () => {
	const navigate = useNavigate()

	/**
	 * Helper function to determine next step using AWS Cognito
	 * @param step - The next step on the flow
	 */
	const handleAuthNextStep = (
		step: SignInOutput | ResetPasswordOutput | undefined,
		data?: {
			email?: string
			code?: string
		},
	) => {
		if (!step) return

		const isSignIn = 'signInStep' in step.nextStep
		const isResetPassword = 'resetPasswordStep' in step.nextStep

		let nextRoute: LinkProps['to'] | undefined

		/**
		 * Depending on the flow (SignIn or ResetPassword), we need to handle the next
		 * step differently.
		 */

		// Sign In Flow
		if (isSignIn) {
			const flow = step as SignInOutput
			const nextStep = flow.nextStep
			const steps = nextStep.signInStep as SIGN_IN_STEPS

			switch (steps) {
				case SIGN_IN_STEPS.enum.CONFIRM_SIGN_IN_WITH_TOTP_CODE: {
					navigate({
						to: '/totp',
						search: (prev) => ({ ...prev }),
					})
					break
				}
				case SIGN_IN_STEPS.enum.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: {
					navigate({
						to: '/setup-password',
						search: (prev) => ({ ...prev }),
					})
					break
				}
				case SIGN_IN_STEPS.enum.RESET_PASSWORD: {
					navigate({
						to: '/confirm-code',
						search: (prev) => ({ ...prev, email: data?.email }),
					})
					break
				}
				case SIGN_IN_STEPS.enum.CONTINUE_SIGN_IN_WITH_TOTP_SETUP: {
					const totpNextStep = flow.nextStep as TotpSetupDetails
					const getSetupUri = totpNextStep.totpSetupDetails?.getSetupUri
					const sharedSecret = totpNextStep.totpSetupDetails?.sharedSecret

					if (!getSetupUri || !sharedSecret) return

					navigate({
						to: '/setup-totp',
						search: (prev) => ({
							...prev,
							totpSecret: getSetupUri('bitlyft').toString(),
							sharedSecret,
						}),
					})
					break
				}
				default:
					break
			}
		}

		// Reset Password Flow
		if (isResetPassword) {
			const flow = step as ResetPasswordOutput
			const nextStep = flow.nextStep
			const steps = nextStep.resetPasswordStep as RESET_PASSWORD_STEPS

			switch (steps) {
				case SIGN_IN_STEPS.enum.CONFIRM_RESET_PASSWORD_WITH_CODE: {
					navigate({
						to: '/confirm-code',
						search: (prev) => ({ ...prev, email: data?.email }),
					})
					break
				}
				default:
					break
			}
		}

		return nextRoute
	}

	return { handleAuthNextStep }
}
