/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as AuthImport } from './routes/_auth'
import { Route as DashboardIndexImport } from './routes/_dashboard/index'
import { Route as DashboardSettingsImport } from './routes/_dashboard/settings'
import { Route as DashboardReportImport } from './routes/_dashboard/report'
import { Route as DashboardMdrImport } from './routes/_dashboard/mdr'
import { Route as DashboardIntegrationsImport } from './routes/_dashboard/integrations'
import { Route as DashboardGraylogImport } from './routes/_dashboard/graylog'
import { Route as DashboardActivityImport } from './routes/_dashboard/activity'
import { Route as AuthTotpImport } from './routes/_auth/totp'
import { Route as AuthSetupTotpImport } from './routes/_auth/setup-totp'
import { Route as AuthSetupPasswordImport } from './routes/_auth/setup-password'
import { Route as AuthResetPasswordImport } from './routes/_auth/reset-password'
import { Route as AuthLogoutImport } from './routes/_auth/logout'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AuthForgotPasswordImport } from './routes/_auth/forgot-password'
import { Route as AuthConfirmCodeImport } from './routes/_auth/confirm-code'
import { Route as DashboardUnauthorizedIndexImport } from './routes/_dashboard/unauthorized.index'
import { Route as DashboardSettingsIndexImport } from './routes/_dashboard/settings/index'
import { Route as DashboardSearchIndexImport } from './routes/_dashboard/search.index'
import { Route as DashboardPlaybooksIndexImport } from './routes/_dashboard/playbooks.index'
import { Route as DashboardMdrIndexImport } from './routes/_dashboard/mdr/index'
import { Route as DashboardIntegrationsIndexImport } from './routes/_dashboard/integrations/index'
import { Route as DashboardGraylogIndexImport } from './routes/_dashboard/graylog/index'
import { Route as DashboardAirIndexImport } from './routes/_dashboard/air.index'
import { Route as DashboardActivityIndexImport } from './routes/_dashboard/activity.index'
import { Route as DashboardActionsIndexImport } from './routes/_dashboard/actions.index'
import { Route as DashboardSettingsTermDatesImport } from './routes/_dashboard/settings/term-dates'
import { Route as DashboardSettingsProfileImport } from './routes/_dashboard/settings/profile'
import { Route as DashboardSettingsNotificationsImport } from './routes/_dashboard/settings/notifications'
import { Route as DashboardPlaybooksIdImport } from './routes/_dashboard/playbooks.$id'
import { Route as DashboardMdrExecutiveReportsImport } from './routes/_dashboard/mdr/executive-reports'
import { Route as DashboardIntegrationsIdImport } from './routes/_dashboard/integrations/$id'
import { Route as DashboardGraylogAlertsImport } from './routes/_dashboard/graylog/alerts'
import { Route as DashboardSettingsTenantSettingsIndexImport } from './routes/_dashboard/settings/tenant-settings.index'
import { Route as DashboardSettingsContactDirectoryIndexImport } from './routes/_dashboard/settings/contact-directory.index'
import { Route as DashboardIntegrationsNewIndexImport } from './routes/_dashboard/integrations/new.index'
import { Route as DashboardGraylogEventDefinitionsIndexImport } from './routes/_dashboard/graylog/event-definitions.index'
import { Route as DashboardGraylogAutomationSettingsIndexImport } from './routes/_dashboard/graylog/automation-settings.index'
import { Route as DashboardIntegrationsNewIdImport } from './routes/_dashboard/integrations/new.$id'
import { Route as DashboardGraylogAutomationSettingsNewIndexImport } from './routes/_dashboard/graylog/automation-settings.new.index'
import { Route as DashboardGraylogAutomationSettingsEditIdImport } from './routes/_dashboard/graylog/automation-settings.edit.$id'

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSettingsRoute = DashboardSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardReportRoute = DashboardReportImport.update({
  id: '/report',
  path: '/report',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardMdrRoute = DashboardMdrImport.update({
  id: '/mdr',
  path: '/mdr',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardIntegrationsRoute = DashboardIntegrationsImport.update({
  id: '/integrations',
  path: '/integrations',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardGraylogRoute = DashboardGraylogImport.update({
  id: '/graylog',
  path: '/graylog',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardActivityRoute = DashboardActivityImport.update({
  id: '/activity',
  path: '/activity',
  getParentRoute: () => DashboardRoute,
} as any)

const AuthTotpRoute = AuthTotpImport.update({
  id: '/totp',
  path: '/totp',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSetupTotpRoute = AuthSetupTotpImport.update({
  id: '/setup-totp',
  path: '/setup-totp',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSetupPasswordRoute = AuthSetupPasswordImport.update({
  id: '/setup-password',
  path: '/setup-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthConfirmCodeRoute = AuthConfirmCodeImport.update({
  id: '/confirm-code',
  path: '/confirm-code',
  getParentRoute: () => AuthRoute,
} as any)

const DashboardUnauthorizedIndexRoute = DashboardUnauthorizedIndexImport.update(
  {
    id: '/unauthorized/',
    path: '/unauthorized/',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardSettingsIndexRoute = DashboardSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardSettingsRoute,
} as any)

const DashboardSearchIndexRoute = DashboardSearchIndexImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPlaybooksIndexRoute = DashboardPlaybooksIndexImport.update({
  id: '/playbooks/',
  path: '/playbooks/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardMdrIndexRoute = DashboardMdrIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardMdrRoute,
} as any)

const DashboardIntegrationsIndexRoute = DashboardIntegrationsIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any,
)

const DashboardGraylogIndexRoute = DashboardGraylogIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardGraylogRoute,
} as any)

const DashboardAirIndexRoute = DashboardAirIndexImport.update({
  id: '/air/',
  path: '/air/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardActivityIndexRoute = DashboardActivityIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardActivityRoute,
} as any)

const DashboardActionsIndexRoute = DashboardActionsIndexImport.update({
  id: '/actions/',
  path: '/actions/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSettingsTermDatesRoute = DashboardSettingsTermDatesImport.update(
  {
    id: '/term-dates',
    path: '/term-dates',
    getParentRoute: () => DashboardSettingsRoute,
  } as any,
)

const DashboardSettingsProfileRoute = DashboardSettingsProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => DashboardSettingsRoute,
} as any)

const DashboardSettingsNotificationsRoute =
  DashboardSettingsNotificationsImport.update({
    id: '/notifications',
    path: '/notifications',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardPlaybooksIdRoute = DashboardPlaybooksIdImport.update({
  id: '/playbooks/$id',
  path: '/playbooks/$id',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardMdrExecutiveReportsRoute =
  DashboardMdrExecutiveReportsImport.update({
    id: '/executive-reports',
    path: '/executive-reports',
    getParentRoute: () => DashboardMdrRoute,
  } as any)

const DashboardIntegrationsIdRoute = DashboardIntegrationsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => DashboardIntegrationsRoute,
} as any)

const DashboardGraylogAlertsRoute = DashboardGraylogAlertsImport.update({
  id: '/alerts',
  path: '/alerts',
  getParentRoute: () => DashboardGraylogRoute,
} as any)

const DashboardSettingsTenantSettingsIndexRoute =
  DashboardSettingsTenantSettingsIndexImport.update({
    id: '/tenant-settings/',
    path: '/tenant-settings/',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardSettingsContactDirectoryIndexRoute =
  DashboardSettingsContactDirectoryIndexImport.update({
    id: '/contact-directory/',
    path: '/contact-directory/',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardIntegrationsNewIndexRoute =
  DashboardIntegrationsNewIndexImport.update({
    id: '/new/',
    path: '/new/',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any)

const DashboardGraylogEventDefinitionsIndexRoute =
  DashboardGraylogEventDefinitionsIndexImport.update({
    id: '/event-definitions/',
    path: '/event-definitions/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardGraylogAutomationSettingsIndexRoute =
  DashboardGraylogAutomationSettingsIndexImport.update({
    id: '/automation-settings/',
    path: '/automation-settings/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardIntegrationsNewIdRoute = DashboardIntegrationsNewIdImport.update(
  {
    id: '/new/$id',
    path: '/new/$id',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any,
)

const DashboardGraylogAutomationSettingsNewIndexRoute =
  DashboardGraylogAutomationSettingsNewIndexImport.update({
    id: '/automation-settings/new/',
    path: '/automation-settings/new/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardGraylogAutomationSettingsEditIdRoute =
  DashboardGraylogAutomationSettingsEditIdImport.update({
    id: '/automation-settings/edit/$id',
    path: '/automation-settings/edit/$id',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/_auth/confirm-code': {
      id: '/_auth/confirm-code'
      path: '/confirm-code'
      fullPath: '/confirm-code'
      preLoaderRoute: typeof AuthConfirmCodeImport
      parentRoute: typeof AuthImport
    }
    '/_auth/forgot-password': {
      id: '/_auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/logout': {
      id: '/_auth/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/setup-password': {
      id: '/_auth/setup-password'
      path: '/setup-password'
      fullPath: '/setup-password'
      preLoaderRoute: typeof AuthSetupPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/setup-totp': {
      id: '/_auth/setup-totp'
      path: '/setup-totp'
      fullPath: '/setup-totp'
      preLoaderRoute: typeof AuthSetupTotpImport
      parentRoute: typeof AuthImport
    }
    '/_auth/totp': {
      id: '/_auth/totp'
      path: '/totp'
      fullPath: '/totp'
      preLoaderRoute: typeof AuthTotpImport
      parentRoute: typeof AuthImport
    }
    '/_dashboard/activity': {
      id: '/_dashboard/activity'
      path: '/activity'
      fullPath: '/activity'
      preLoaderRoute: typeof DashboardActivityImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog': {
      id: '/_dashboard/graylog'
      path: '/graylog'
      fullPath: '/graylog'
      preLoaderRoute: typeof DashboardGraylogImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/integrations': {
      id: '/_dashboard/integrations'
      path: '/integrations'
      fullPath: '/integrations'
      preLoaderRoute: typeof DashboardIntegrationsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/mdr': {
      id: '/_dashboard/mdr'
      path: '/mdr'
      fullPath: '/mdr'
      preLoaderRoute: typeof DashboardMdrImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/report': {
      id: '/_dashboard/report'
      path: '/report'
      fullPath: '/report'
      preLoaderRoute: typeof DashboardReportImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings': {
      id: '/_dashboard/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof DashboardSettingsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog/alerts': {
      id: '/_dashboard/graylog/alerts'
      path: '/alerts'
      fullPath: '/graylog/alerts'
      preLoaderRoute: typeof DashboardGraylogAlertsImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/$id': {
      id: '/_dashboard/integrations/$id'
      path: '/$id'
      fullPath: '/integrations/$id'
      preLoaderRoute: typeof DashboardIntegrationsIdImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/mdr/executive-reports': {
      id: '/_dashboard/mdr/executive-reports'
      path: '/executive-reports'
      fullPath: '/mdr/executive-reports'
      preLoaderRoute: typeof DashboardMdrExecutiveReportsImport
      parentRoute: typeof DashboardMdrImport
    }
    '/_dashboard/playbooks/$id': {
      id: '/_dashboard/playbooks/$id'
      path: '/playbooks/$id'
      fullPath: '/playbooks/$id'
      preLoaderRoute: typeof DashboardPlaybooksIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings/notifications': {
      id: '/_dashboard/settings/notifications'
      path: '/notifications'
      fullPath: '/settings/notifications'
      preLoaderRoute: typeof DashboardSettingsNotificationsImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/profile': {
      id: '/_dashboard/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof DashboardSettingsProfileImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/term-dates': {
      id: '/_dashboard/settings/term-dates'
      path: '/term-dates'
      fullPath: '/settings/term-dates'
      preLoaderRoute: typeof DashboardSettingsTermDatesImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/actions/': {
      id: '/_dashboard/actions/'
      path: '/actions'
      fullPath: '/actions'
      preLoaderRoute: typeof DashboardActionsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/activity/': {
      id: '/_dashboard/activity/'
      path: '/'
      fullPath: '/activity/'
      preLoaderRoute: typeof DashboardActivityIndexImport
      parentRoute: typeof DashboardActivityImport
    }
    '/_dashboard/air/': {
      id: '/_dashboard/air/'
      path: '/air'
      fullPath: '/air'
      preLoaderRoute: typeof DashboardAirIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog/': {
      id: '/_dashboard/graylog/'
      path: '/'
      fullPath: '/graylog/'
      preLoaderRoute: typeof DashboardGraylogIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/': {
      id: '/_dashboard/integrations/'
      path: '/'
      fullPath: '/integrations/'
      preLoaderRoute: typeof DashboardIntegrationsIndexImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/mdr/': {
      id: '/_dashboard/mdr/'
      path: '/'
      fullPath: '/mdr/'
      preLoaderRoute: typeof DashboardMdrIndexImport
      parentRoute: typeof DashboardMdrImport
    }
    '/_dashboard/playbooks/': {
      id: '/_dashboard/playbooks/'
      path: '/playbooks'
      fullPath: '/playbooks'
      preLoaderRoute: typeof DashboardPlaybooksIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/search/': {
      id: '/_dashboard/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof DashboardSearchIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings/': {
      id: '/_dashboard/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof DashboardSettingsIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/unauthorized/': {
      id: '/_dashboard/unauthorized/'
      path: '/unauthorized'
      fullPath: '/unauthorized'
      preLoaderRoute: typeof DashboardUnauthorizedIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/integrations/new/$id': {
      id: '/_dashboard/integrations/new/$id'
      path: '/new/$id'
      fullPath: '/integrations/new/$id'
      preLoaderRoute: typeof DashboardIntegrationsNewIdImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/graylog/automation-settings/': {
      id: '/_dashboard/graylog/automation-settings/'
      path: '/automation-settings'
      fullPath: '/graylog/automation-settings'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/graylog/event-definitions/': {
      id: '/_dashboard/graylog/event-definitions/'
      path: '/event-definitions'
      fullPath: '/graylog/event-definitions'
      preLoaderRoute: typeof DashboardGraylogEventDefinitionsIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/new/': {
      id: '/_dashboard/integrations/new/'
      path: '/new'
      fullPath: '/integrations/new'
      preLoaderRoute: typeof DashboardIntegrationsNewIndexImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/settings/contact-directory/': {
      id: '/_dashboard/settings/contact-directory/'
      path: '/contact-directory'
      fullPath: '/settings/contact-directory'
      preLoaderRoute: typeof DashboardSettingsContactDirectoryIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/tenant-settings/': {
      id: '/_dashboard/settings/tenant-settings/'
      path: '/tenant-settings'
      fullPath: '/settings/tenant-settings'
      preLoaderRoute: typeof DashboardSettingsTenantSettingsIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/graylog/automation-settings/edit/$id': {
      id: '/_dashboard/graylog/automation-settings/edit/$id'
      path: '/automation-settings/edit/$id'
      fullPath: '/graylog/automation-settings/edit/$id'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsEditIdImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/graylog/automation-settings/new/': {
      id: '/_dashboard/graylog/automation-settings/new/'
      path: '/automation-settings/new'
      fullPath: '/graylog/automation-settings/new'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsNewIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthConfirmCodeRoute: typeof AuthConfirmCodeRoute
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthLogoutRoute: typeof AuthLogoutRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  AuthSetupPasswordRoute: typeof AuthSetupPasswordRoute
  AuthSetupTotpRoute: typeof AuthSetupTotpRoute
  AuthTotpRoute: typeof AuthTotpRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthConfirmCodeRoute: AuthConfirmCodeRoute,
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthLogoutRoute: AuthLogoutRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  AuthSetupPasswordRoute: AuthSetupPasswordRoute,
  AuthSetupTotpRoute: AuthSetupTotpRoute,
  AuthTotpRoute: AuthTotpRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface DashboardActivityRouteChildren {
  DashboardActivityIndexRoute: typeof DashboardActivityIndexRoute
}

const DashboardActivityRouteChildren: DashboardActivityRouteChildren = {
  DashboardActivityIndexRoute: DashboardActivityIndexRoute,
}

const DashboardActivityRouteWithChildren =
  DashboardActivityRoute._addFileChildren(DashboardActivityRouteChildren)

interface DashboardGraylogRouteChildren {
  DashboardGraylogAlertsRoute: typeof DashboardGraylogAlertsRoute
  DashboardGraylogIndexRoute: typeof DashboardGraylogIndexRoute
  DashboardGraylogAutomationSettingsIndexRoute: typeof DashboardGraylogAutomationSettingsIndexRoute
  DashboardGraylogEventDefinitionsIndexRoute: typeof DashboardGraylogEventDefinitionsIndexRoute
  DashboardGraylogAutomationSettingsEditIdRoute: typeof DashboardGraylogAutomationSettingsEditIdRoute
  DashboardGraylogAutomationSettingsNewIndexRoute: typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

const DashboardGraylogRouteChildren: DashboardGraylogRouteChildren = {
  DashboardGraylogAlertsRoute: DashboardGraylogAlertsRoute,
  DashboardGraylogIndexRoute: DashboardGraylogIndexRoute,
  DashboardGraylogAutomationSettingsIndexRoute:
    DashboardGraylogAutomationSettingsIndexRoute,
  DashboardGraylogEventDefinitionsIndexRoute:
    DashboardGraylogEventDefinitionsIndexRoute,
  DashboardGraylogAutomationSettingsEditIdRoute:
    DashboardGraylogAutomationSettingsEditIdRoute,
  DashboardGraylogAutomationSettingsNewIndexRoute:
    DashboardGraylogAutomationSettingsNewIndexRoute,
}

const DashboardGraylogRouteWithChildren =
  DashboardGraylogRoute._addFileChildren(DashboardGraylogRouteChildren)

interface DashboardIntegrationsRouteChildren {
  DashboardIntegrationsIdRoute: typeof DashboardIntegrationsIdRoute
  DashboardIntegrationsIndexRoute: typeof DashboardIntegrationsIndexRoute
  DashboardIntegrationsNewIdRoute: typeof DashboardIntegrationsNewIdRoute
  DashboardIntegrationsNewIndexRoute: typeof DashboardIntegrationsNewIndexRoute
}

const DashboardIntegrationsRouteChildren: DashboardIntegrationsRouteChildren = {
  DashboardIntegrationsIdRoute: DashboardIntegrationsIdRoute,
  DashboardIntegrationsIndexRoute: DashboardIntegrationsIndexRoute,
  DashboardIntegrationsNewIdRoute: DashboardIntegrationsNewIdRoute,
  DashboardIntegrationsNewIndexRoute: DashboardIntegrationsNewIndexRoute,
}

const DashboardIntegrationsRouteWithChildren =
  DashboardIntegrationsRoute._addFileChildren(
    DashboardIntegrationsRouteChildren,
  )

interface DashboardMdrRouteChildren {
  DashboardMdrExecutiveReportsRoute: typeof DashboardMdrExecutiveReportsRoute
  DashboardMdrIndexRoute: typeof DashboardMdrIndexRoute
}

const DashboardMdrRouteChildren: DashboardMdrRouteChildren = {
  DashboardMdrExecutiveReportsRoute: DashboardMdrExecutiveReportsRoute,
  DashboardMdrIndexRoute: DashboardMdrIndexRoute,
}

const DashboardMdrRouteWithChildren = DashboardMdrRoute._addFileChildren(
  DashboardMdrRouteChildren,
)

interface DashboardSettingsRouteChildren {
  DashboardSettingsNotificationsRoute: typeof DashboardSettingsNotificationsRoute
  DashboardSettingsProfileRoute: typeof DashboardSettingsProfileRoute
  DashboardSettingsTermDatesRoute: typeof DashboardSettingsTermDatesRoute
  DashboardSettingsIndexRoute: typeof DashboardSettingsIndexRoute
  DashboardSettingsContactDirectoryIndexRoute: typeof DashboardSettingsContactDirectoryIndexRoute
  DashboardSettingsTenantSettingsIndexRoute: typeof DashboardSettingsTenantSettingsIndexRoute
}

const DashboardSettingsRouteChildren: DashboardSettingsRouteChildren = {
  DashboardSettingsNotificationsRoute: DashboardSettingsNotificationsRoute,
  DashboardSettingsProfileRoute: DashboardSettingsProfileRoute,
  DashboardSettingsTermDatesRoute: DashboardSettingsTermDatesRoute,
  DashboardSettingsIndexRoute: DashboardSettingsIndexRoute,
  DashboardSettingsContactDirectoryIndexRoute:
    DashboardSettingsContactDirectoryIndexRoute,
  DashboardSettingsTenantSettingsIndexRoute:
    DashboardSettingsTenantSettingsIndexRoute,
}

const DashboardSettingsRouteWithChildren =
  DashboardSettingsRoute._addFileChildren(DashboardSettingsRouteChildren)

interface DashboardRouteChildren {
  DashboardActivityRoute: typeof DashboardActivityRouteWithChildren
  DashboardGraylogRoute: typeof DashboardGraylogRouteWithChildren
  DashboardIntegrationsRoute: typeof DashboardIntegrationsRouteWithChildren
  DashboardMdrRoute: typeof DashboardMdrRouteWithChildren
  DashboardReportRoute: typeof DashboardReportRoute
  DashboardSettingsRoute: typeof DashboardSettingsRouteWithChildren
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardPlaybooksIdRoute: typeof DashboardPlaybooksIdRoute
  DashboardActionsIndexRoute: typeof DashboardActionsIndexRoute
  DashboardAirIndexRoute: typeof DashboardAirIndexRoute
  DashboardPlaybooksIndexRoute: typeof DashboardPlaybooksIndexRoute
  DashboardSearchIndexRoute: typeof DashboardSearchIndexRoute
  DashboardUnauthorizedIndexRoute: typeof DashboardUnauthorizedIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardActivityRoute: DashboardActivityRouteWithChildren,
  DashboardGraylogRoute: DashboardGraylogRouteWithChildren,
  DashboardIntegrationsRoute: DashboardIntegrationsRouteWithChildren,
  DashboardMdrRoute: DashboardMdrRouteWithChildren,
  DashboardReportRoute: DashboardReportRoute,
  DashboardSettingsRoute: DashboardSettingsRouteWithChildren,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardPlaybooksIdRoute: DashboardPlaybooksIdRoute,
  DashboardActionsIndexRoute: DashboardActionsIndexRoute,
  DashboardAirIndexRoute: DashboardAirIndexRoute,
  DashboardPlaybooksIndexRoute: DashboardPlaybooksIndexRoute,
  DashboardSearchIndexRoute: DashboardSearchIndexRoute,
  DashboardUnauthorizedIndexRoute: DashboardUnauthorizedIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardRouteWithChildren
  '/confirm-code': typeof AuthConfirmCodeRoute
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/logout': typeof AuthLogoutRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/setup-password': typeof AuthSetupPasswordRoute
  '/setup-totp': typeof AuthSetupTotpRoute
  '/totp': typeof AuthTotpRoute
  '/activity': typeof DashboardActivityRouteWithChildren
  '/graylog': typeof DashboardGraylogRouteWithChildren
  '/integrations': typeof DashboardIntegrationsRouteWithChildren
  '/mdr': typeof DashboardMdrRouteWithChildren
  '/report': typeof DashboardReportRoute
  '/settings': typeof DashboardSettingsRouteWithChildren
  '/': typeof DashboardIndexRoute
  '/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/mdr/executive-reports': typeof DashboardMdrExecutiveReportsRoute
  '/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/settings/profile': typeof DashboardSettingsProfileRoute
  '/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/actions': typeof DashboardActionsIndexRoute
  '/activity/': typeof DashboardActivityIndexRoute
  '/air': typeof DashboardAirIndexRoute
  '/graylog/': typeof DashboardGraylogIndexRoute
  '/integrations/': typeof DashboardIntegrationsIndexRoute
  '/mdr/': typeof DashboardMdrIndexRoute
  '/playbooks': typeof DashboardPlaybooksIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/settings/': typeof DashboardSettingsIndexRoute
  '/unauthorized': typeof DashboardUnauthorizedIndexRoute
  '/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/graylog/automation-settings': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/graylog/event-definitions': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/integrations/new': typeof DashboardIntegrationsNewIndexRoute
  '/settings/contact-directory': typeof DashboardSettingsContactDirectoryIndexRoute
  '/settings/tenant-settings': typeof DashboardSettingsTenantSettingsIndexRoute
  '/graylog/automation-settings/edit/$id': typeof DashboardGraylogAutomationSettingsEditIdRoute
  '/graylog/automation-settings/new': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/confirm-code': typeof AuthConfirmCodeRoute
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/logout': typeof AuthLogoutRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/setup-password': typeof AuthSetupPasswordRoute
  '/setup-totp': typeof AuthSetupTotpRoute
  '/totp': typeof AuthTotpRoute
  '/report': typeof DashboardReportRoute
  '/': typeof DashboardIndexRoute
  '/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/mdr/executive-reports': typeof DashboardMdrExecutiveReportsRoute
  '/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/settings/profile': typeof DashboardSettingsProfileRoute
  '/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/actions': typeof DashboardActionsIndexRoute
  '/activity': typeof DashboardActivityIndexRoute
  '/air': typeof DashboardAirIndexRoute
  '/graylog': typeof DashboardGraylogIndexRoute
  '/integrations': typeof DashboardIntegrationsIndexRoute
  '/mdr': typeof DashboardMdrIndexRoute
  '/playbooks': typeof DashboardPlaybooksIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/settings': typeof DashboardSettingsIndexRoute
  '/unauthorized': typeof DashboardUnauthorizedIndexRoute
  '/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/graylog/automation-settings': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/graylog/event-definitions': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/integrations/new': typeof DashboardIntegrationsNewIndexRoute
  '/settings/contact-directory': typeof DashboardSettingsContactDirectoryIndexRoute
  '/settings/tenant-settings': typeof DashboardSettingsTenantSettingsIndexRoute
  '/graylog/automation-settings/edit/$id': typeof DashboardGraylogAutomationSettingsEditIdRoute
  '/graylog/automation-settings/new': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_dashboard': typeof DashboardRouteWithChildren
  '/_auth/confirm-code': typeof AuthConfirmCodeRoute
  '/_auth/forgot-password': typeof AuthForgotPasswordRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/logout': typeof AuthLogoutRoute
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/_auth/setup-password': typeof AuthSetupPasswordRoute
  '/_auth/setup-totp': typeof AuthSetupTotpRoute
  '/_auth/totp': typeof AuthTotpRoute
  '/_dashboard/activity': typeof DashboardActivityRouteWithChildren
  '/_dashboard/graylog': typeof DashboardGraylogRouteWithChildren
  '/_dashboard/integrations': typeof DashboardIntegrationsRouteWithChildren
  '/_dashboard/mdr': typeof DashboardMdrRouteWithChildren
  '/_dashboard/report': typeof DashboardReportRoute
  '/_dashboard/settings': typeof DashboardSettingsRouteWithChildren
  '/_dashboard/': typeof DashboardIndexRoute
  '/_dashboard/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/_dashboard/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/_dashboard/mdr/executive-reports': typeof DashboardMdrExecutiveReportsRoute
  '/_dashboard/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/_dashboard/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/_dashboard/settings/profile': typeof DashboardSettingsProfileRoute
  '/_dashboard/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/_dashboard/actions/': typeof DashboardActionsIndexRoute
  '/_dashboard/activity/': typeof DashboardActivityIndexRoute
  '/_dashboard/air/': typeof DashboardAirIndexRoute
  '/_dashboard/graylog/': typeof DashboardGraylogIndexRoute
  '/_dashboard/integrations/': typeof DashboardIntegrationsIndexRoute
  '/_dashboard/mdr/': typeof DashboardMdrIndexRoute
  '/_dashboard/playbooks/': typeof DashboardPlaybooksIndexRoute
  '/_dashboard/search/': typeof DashboardSearchIndexRoute
  '/_dashboard/settings/': typeof DashboardSettingsIndexRoute
  '/_dashboard/unauthorized/': typeof DashboardUnauthorizedIndexRoute
  '/_dashboard/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/_dashboard/graylog/automation-settings/': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/_dashboard/graylog/event-definitions/': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/_dashboard/integrations/new/': typeof DashboardIntegrationsNewIndexRoute
  '/_dashboard/settings/contact-directory/': typeof DashboardSettingsContactDirectoryIndexRoute
  '/_dashboard/settings/tenant-settings/': typeof DashboardSettingsTenantSettingsIndexRoute
  '/_dashboard/graylog/automation-settings/edit/$id': typeof DashboardGraylogAutomationSettingsEditIdRoute
  '/_dashboard/graylog/automation-settings/new/': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/confirm-code'
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/reset-password'
    | '/setup-password'
    | '/setup-totp'
    | '/totp'
    | '/activity'
    | '/graylog'
    | '/integrations'
    | '/mdr'
    | '/report'
    | '/settings'
    | '/'
    | '/graylog/alerts'
    | '/integrations/$id'
    | '/mdr/executive-reports'
    | '/playbooks/$id'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/term-dates'
    | '/actions'
    | '/activity/'
    | '/air'
    | '/graylog/'
    | '/integrations/'
    | '/mdr/'
    | '/playbooks'
    | '/search'
    | '/settings/'
    | '/unauthorized'
    | '/integrations/new/$id'
    | '/graylog/automation-settings'
    | '/graylog/event-definitions'
    | '/integrations/new'
    | '/settings/contact-directory'
    | '/settings/tenant-settings'
    | '/graylog/automation-settings/edit/$id'
    | '/graylog/automation-settings/new'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/confirm-code'
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/reset-password'
    | '/setup-password'
    | '/setup-totp'
    | '/totp'
    | '/report'
    | '/'
    | '/graylog/alerts'
    | '/integrations/$id'
    | '/mdr/executive-reports'
    | '/playbooks/$id'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/term-dates'
    | '/actions'
    | '/activity'
    | '/air'
    | '/graylog'
    | '/integrations'
    | '/mdr'
    | '/playbooks'
    | '/search'
    | '/settings'
    | '/unauthorized'
    | '/integrations/new/$id'
    | '/graylog/automation-settings'
    | '/graylog/event-definitions'
    | '/integrations/new'
    | '/settings/contact-directory'
    | '/settings/tenant-settings'
    | '/graylog/automation-settings/edit/$id'
    | '/graylog/automation-settings/new'
  id:
    | '__root__'
    | '/_auth'
    | '/_dashboard'
    | '/_auth/confirm-code'
    | '/_auth/forgot-password'
    | '/_auth/login'
    | '/_auth/logout'
    | '/_auth/reset-password'
    | '/_auth/setup-password'
    | '/_auth/setup-totp'
    | '/_auth/totp'
    | '/_dashboard/activity'
    | '/_dashboard/graylog'
    | '/_dashboard/integrations'
    | '/_dashboard/mdr'
    | '/_dashboard/report'
    | '/_dashboard/settings'
    | '/_dashboard/'
    | '/_dashboard/graylog/alerts'
    | '/_dashboard/integrations/$id'
    | '/_dashboard/mdr/executive-reports'
    | '/_dashboard/playbooks/$id'
    | '/_dashboard/settings/notifications'
    | '/_dashboard/settings/profile'
    | '/_dashboard/settings/term-dates'
    | '/_dashboard/actions/'
    | '/_dashboard/activity/'
    | '/_dashboard/air/'
    | '/_dashboard/graylog/'
    | '/_dashboard/integrations/'
    | '/_dashboard/mdr/'
    | '/_dashboard/playbooks/'
    | '/_dashboard/search/'
    | '/_dashboard/settings/'
    | '/_dashboard/unauthorized/'
    | '/_dashboard/integrations/new/$id'
    | '/_dashboard/graylog/automation-settings/'
    | '/_dashboard/graylog/event-definitions/'
    | '/_dashboard/integrations/new/'
    | '/_dashboard/settings/contact-directory/'
    | '/_dashboard/settings/tenant-settings/'
    | '/_dashboard/graylog/automation-settings/edit/$id'
    | '/_dashboard/graylog/automation-settings/new/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  DashboardRoute: typeof DashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  DashboardRoute: DashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_dashboard"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/confirm-code",
        "/_auth/forgot-password",
        "/_auth/login",
        "/_auth/logout",
        "/_auth/reset-password",
        "/_auth/setup-password",
        "/_auth/setup-totp",
        "/_auth/totp"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/activity",
        "/_dashboard/graylog",
        "/_dashboard/integrations",
        "/_dashboard/mdr",
        "/_dashboard/report",
        "/_dashboard/settings",
        "/_dashboard/",
        "/_dashboard/playbooks/$id",
        "/_dashboard/actions/",
        "/_dashboard/air/",
        "/_dashboard/playbooks/",
        "/_dashboard/search/",
        "/_dashboard/unauthorized/"
      ]
    },
    "/_auth/confirm-code": {
      "filePath": "_auth/confirm-code.tsx",
      "parent": "/_auth"
    },
    "/_auth/forgot-password": {
      "filePath": "_auth/forgot-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/logout": {
      "filePath": "_auth/logout.tsx",
      "parent": "/_auth"
    },
    "/_auth/reset-password": {
      "filePath": "_auth/reset-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/setup-password": {
      "filePath": "_auth/setup-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/setup-totp": {
      "filePath": "_auth/setup-totp.tsx",
      "parent": "/_auth"
    },
    "/_auth/totp": {
      "filePath": "_auth/totp.tsx",
      "parent": "/_auth"
    },
    "/_dashboard/activity": {
      "filePath": "_dashboard/activity.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/activity/"
      ]
    },
    "/_dashboard/graylog": {
      "filePath": "_dashboard/graylog.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/graylog/alerts",
        "/_dashboard/graylog/",
        "/_dashboard/graylog/automation-settings/",
        "/_dashboard/graylog/event-definitions/",
        "/_dashboard/graylog/automation-settings/edit/$id",
        "/_dashboard/graylog/automation-settings/new/"
      ]
    },
    "/_dashboard/integrations": {
      "filePath": "_dashboard/integrations.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/integrations/$id",
        "/_dashboard/integrations/",
        "/_dashboard/integrations/new/$id",
        "/_dashboard/integrations/new/"
      ]
    },
    "/_dashboard/mdr": {
      "filePath": "_dashboard/mdr.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/mdr/executive-reports",
        "/_dashboard/mdr/"
      ]
    },
    "/_dashboard/report": {
      "filePath": "_dashboard/report.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings": {
      "filePath": "_dashboard/settings.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/settings/notifications",
        "/_dashboard/settings/profile",
        "/_dashboard/settings/term-dates",
        "/_dashboard/settings/",
        "/_dashboard/settings/contact-directory/",
        "/_dashboard/settings/tenant-settings/"
      ]
    },
    "/_dashboard/": {
      "filePath": "_dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/graylog/alerts": {
      "filePath": "_dashboard/graylog/alerts.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/$id": {
      "filePath": "_dashboard/integrations/$id.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/mdr/executive-reports": {
      "filePath": "_dashboard/mdr/executive-reports.tsx",
      "parent": "/_dashboard/mdr"
    },
    "/_dashboard/playbooks/$id": {
      "filePath": "_dashboard/playbooks.$id.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings/notifications": {
      "filePath": "_dashboard/settings/notifications.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/profile": {
      "filePath": "_dashboard/settings/profile.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/term-dates": {
      "filePath": "_dashboard/settings/term-dates.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/actions/": {
      "filePath": "_dashboard/actions.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/activity/": {
      "filePath": "_dashboard/activity.index.tsx",
      "parent": "/_dashboard/activity"
    },
    "/_dashboard/air/": {
      "filePath": "_dashboard/air.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/graylog/": {
      "filePath": "_dashboard/graylog/index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/": {
      "filePath": "_dashboard/integrations/index.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/mdr/": {
      "filePath": "_dashboard/mdr/index.tsx",
      "parent": "/_dashboard/mdr"
    },
    "/_dashboard/playbooks/": {
      "filePath": "_dashboard/playbooks.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/search/": {
      "filePath": "_dashboard/search.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings/": {
      "filePath": "_dashboard/settings/index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/unauthorized/": {
      "filePath": "_dashboard/unauthorized.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/integrations/new/$id": {
      "filePath": "_dashboard/integrations/new.$id.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/graylog/automation-settings/": {
      "filePath": "_dashboard/graylog/automation-settings.index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/graylog/event-definitions/": {
      "filePath": "_dashboard/graylog/event-definitions.index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/new/": {
      "filePath": "_dashboard/integrations/new.index.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/settings/contact-directory/": {
      "filePath": "_dashboard/settings/contact-directory.index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/tenant-settings/": {
      "filePath": "_dashboard/settings/tenant-settings.index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/graylog/automation-settings/edit/$id": {
      "filePath": "_dashboard/graylog/automation-settings.edit.$id.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/graylog/automation-settings/new/": {
      "filePath": "_dashboard/graylog/automation-settings.new.index.tsx",
      "parent": "/_dashboard/graylog"
    }
  }
}
ROUTE_MANIFEST_END */
