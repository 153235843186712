import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'

import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'

import { LoadingButton } from '@/components/buttons/loading-button'
import { PasswordInput } from '@/components/common/password-input'
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '@/components/ui/input-otp'

import { useMe } from '../queries'

import { schema } from './new-password-validation'

export const NewPasswordForm = () => {
	const {
		data: { user },
	} = useMe()

	const [dialogStep, setDialogStep] = useState<'notify' | 'code'>('notify')

	// Controls Dialog state
	const [isOpen, setIsOpen] = useState<boolean>(false)

	// Controls loading state
	const [isLoading, setIsLoading] = useState<boolean>(false)

	// Form validation
	const form = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			code: '',
			password: '',
			confirmPassword: '',
		},
	})

	const onSubmit = form.handleSubmit(async (data) => {
		// Prevent multiple form submissions
		if (isLoading) return

		// Start loading state
		setIsLoading(true)

		// Grab the form data
		const { password, code } = data

		try {
			// Send request to Amplify
			await confirmResetPassword({
				confirmationCode: code,
				newPassword: password,
				username: user?.uname as string,
			})

			// Show user a success message
			toast('Password reset successfully', {
				description: 'You can now login with your new password',
			})

			// Reset the form
			setIsLoading(false)
			setIsOpen(false)
			form.reset()
		} catch (error) {
			const err = error as { name: string; message: string }
			setIsLoading(false)

			form.setError('code', {
				type: 'custom',
				message: err.message,
			})
		}
	})

	// Request a confirmation code
	const requestConfirmationCode = async () => {
		setIsLoading(true)
		setDialogStep('notify')

		try {
			await resetPassword({
				username: user?.uname as string,
			})

			// Open dialog and reset loading state
			setIsOpen(true)
			setIsLoading(false)
		} catch (error) {
			const err = error as { name: string; message: string }
			setIsLoading(false)

			form.setError('password', {
				type: 'custom',
				message: err.message,
			})
		}
	}

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="flex w-full flex-col items-start justify-center gap-4 px-2"
			>
				<div className="flex w-full flex-col items-start justify-start gap-2 sm:w-1/2">
					<PasswordInput form={form} name="password" label="New Password" />
					<PasswordInput
						form={form}
						name="confirmPassword"
						label="Confirm Password"
						isConfirm
					/>

					<LoadingButton
						isLoading={isLoading}
						type="button"
						onClick={requestConfirmationCode}
						variant="outline"
					>
						Update Password
					</LoadingButton>

					<Dialog open={isOpen} onOpenChange={setIsOpen}>
						<DialogContent className="flex flex-col items-start justify-center">
							<DialogHeader>
								<DialogTitle>Confirmation Code</DialogTitle>
								<DialogDescription>
									Enter the code sent to your email
								</DialogDescription>
							</DialogHeader>

							{dialogStep === 'notify' ? (
								<div className="flex w-full flex-col items-center justify-center gap-4 py-2">
									<p className="text-text-primary">
										We've sent you a confirmation code to the following email
										address:
									</p>
									<input
										type="email"
										name="email"
										value={user?.uname}
										placeholder="Email address"
										className="w-full rounded-md border border-text-secondary bg-text-disabled/20 p-4 text-sm text-text-secondary text-opacity-65 placeholder-text-secondary"
										readOnly
										aria-readonly
										disabled
									/>
									<p className="text-sm text-muted-foreground">
										Make sure to grab your confirmation code before continuing.
									</p>
									<LoadingButton
										isLoading={isLoading}
										type="button"
										onClick={() => {
											setDialogStep('code')
										}}
										variant="outline"
									>
										Continue
									</LoadingButton>
								</div>
							) : (
								<div className="flex w-full flex-col items-center justify-center gap-4 py-2">
									<FormField
										control={form.control}
										name="code"
										render={({ field }) => (
											<FormItem className="mb-4 flex w-full flex-col items-center justify-center">
												<FormLabel className="mb-2 text-text-primary">
													Confirmation Code
												</FormLabel>
												<FormControl>
													<InputOTP
														maxLength={6}
														className="w-full"
														{...field}
														autoFocus
													>
														<InputOTPGroup>
															<InputOTPSlot index={0} />
															<InputOTPSlot index={1} />
															<InputOTPSlot index={2} />
														</InputOTPGroup>
														<InputOTPSeparator />
														<InputOTPGroup>
															<InputOTPSlot index={3} />
															<InputOTPSlot index={4} />
															<InputOTPSlot index={5} />
														</InputOTPGroup>
													</InputOTP>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<LoadingButton
										isLoading={isLoading}
										type="submit"
										onClick={onSubmit}
										variant="outline"
									>
										Update Password
									</LoadingButton>
								</div>
							)}
						</DialogContent>
					</Dialog>
				</div>
			</form>
		</Form>
	)
}
