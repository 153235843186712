import { lazy, Suspense } from 'react'

import { Link } from '@tanstack/react-router'

import { Book, File, Frame, Loader2, Logs, Play, Search } from 'lucide-react'

import { appVersion } from '@/config/constants'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'
import { useCheckIntegrations } from '@/features/integrations/hooks/useCheckIntegrations'
import { useTenantDetails } from '@/features/tenant/queries'

import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarGroup,
	SidebarGroupLabel,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from '@/components/ui/sidebar'

import { Graylog } from './graylog'
import { LogoWhite } from './logo-white'

const UserButton = lazy(() => import('./user-button'))
const SidebarTenantSelect = lazy(() => import('./sidebar-tenant-select'))

/**
 * Sidebar component
 * - Contains the logo and menu, memoized to prevent unnecessary re-renders
 */
export const AppSidebar = () => {
	const { hasGraylog } = useCheckIntegrations()
	const {
		data: { sku_info },
	} = useTenantDetails()

	return (
		<Sidebar variant="inset" collapsible="icon" className="z-20">
			<SidebarHeader>
				<SidebarTenantSelect />
			</SidebarHeader>
			<SidebarContent>
				<SidebarGroup>
					<SidebarGroupLabel>Application</SidebarGroupLabel>

					<SidebarMenu>
						{sku_info.is_search_enabled && (
							<>
								<SidebarMenuItem>
									<SidebarMenuButton tooltip="Air" asChild>
										<Link
											to="/air"
											activeProps={{ className: 'text-sidebar-primary' }}
										>
											<LogoWhite />
											<span>Air</span>
										</Link>
									</SidebarMenuButton>
								</SidebarMenuItem>
								<SidebarMenuItem>
									<SidebarMenuButton tooltip="Log Search" asChild>
										<Link
											to="/search"
											activeProps={{ className: 'text-sidebar-primary' }}
										>
											<Search />
											<span>Log Search</span>
										</Link>
									</SidebarMenuButton>
								</SidebarMenuItem>
							</>
						)}

						{hasGraylog && (
							<SidebarMenuItem>
								<SidebarMenuButton tooltip="Graylog" asChild>
									<Link
										to="/graylog"
										activeProps={{ className: 'text-sidebar-primary' }}
									>
										<Graylog />
										<span>Graylog</span>
									</Link>
								</SidebarMenuButton>
							</SidebarMenuItem>
						)}
					</SidebarMenu>
				</SidebarGroup>

				<SidebarGroup>
					<SidebarGroupLabel>Remediation</SidebarGroupLabel>
					<SidebarMenu>
						<HasRole role={Roles.enum.Analyst}>
							<SidebarMenuItem>
								<SidebarMenuButton tooltip="Playbooks" asChild>
									<Link
										to="/playbooks"
										activeProps={{ className: 'text-sidebar-primary' }}
									>
										<Book />
										<span>Playbooks</span>
									</Link>
								</SidebarMenuButton>
							</SidebarMenuItem>

							<SidebarMenuItem>
								<SidebarMenuButton tooltip="Actions" asChild>
									<Link
										to="/actions"
										activeProps={{ className: 'text-sidebar-primary' }}
									>
										<Play />
										<span>Actions</span>
									</Link>
								</SidebarMenuButton>
							</SidebarMenuItem>
						</HasRole>

						<SidebarMenuItem>
							<SidebarMenuButton tooltip="Activity" asChild>
								<Link
									to="/activity"
									activeProps={{ className: 'text-sidebar-primary' }}
								>
									<Logs />
									<span>Activity</span>
								</Link>
							</SidebarMenuButton>
						</SidebarMenuItem>
					</SidebarMenu>
				</SidebarGroup>

				<SidebarGroup>
					<SidebarGroupLabel>Integrations</SidebarGroupLabel>
					<SidebarMenu>
						<SidebarMenuItem>
							<SidebarMenuButton tooltip="Integrations Overview" asChild>
								<Link
									to="/integrations"
									activeProps={{ className: 'text-sidebar-primary' }}
								>
									<Frame />
									<span>Overview</span>
								</Link>
							</SidebarMenuButton>
						</SidebarMenuItem>
					</SidebarMenu>
				</SidebarGroup>

				{sku_info.is_mdr_enabled && (
					<SidebarGroup>
						<SidebarGroupLabel>Reports</SidebarGroupLabel>
						<SidebarMenu>
							<SidebarMenuItem>
								<SidebarMenuButton tooltip="Executive Reports" asChild>
									<Link
										to="/mdr/executive-reports"
										activeProps={{ className: 'text-sidebar-primary' }}
									>
										<File />
										<span>Executive Reports</span>
									</Link>
								</SidebarMenuButton>
							</SidebarMenuItem>
						</SidebarMenu>
					</SidebarGroup>
				)}
			</SidebarContent>
			<SidebarFooter>
				<Suspense fallback={<UserButtonLoader />}>
					<UserButton />
				</Suspense>
				<p className="mt-2 text-center text-xs text-muted-foreground">
					v{appVersion}
				</p>
			</SidebarFooter>
		</Sidebar>
	)
}

function UserButtonLoader() {
	return (
		<div className="flex size-8 animate-pulse items-center justify-center rounded-md bg-muted">
			<Loader2 className="size-4 animate-spin" />
		</div>
	)
}
