import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { redirectHome } from '@/utils'

import { integrationQueries } from '@/features/integrations/queries'
import { searchSchema } from '@/features/search/types'
import { getCurrentSKU, getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import QueryBoundary from '@/components/common/query-boundary'
import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import { Skeleton } from '@/components/ui/skeleton'

import type { TenantSKU } from '@/features/tenant/types'
import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const QueryInput = lazy(
	() => import('@/features/search/components/query-input'),
)
const SearchFilters = lazy(
	() => import('@/features/search/components/search-filters'),
)
const TimeRange = lazy(() => import('@/features/search/components/time-range'))
const IntegrationSelect = lazy(
	() => import('@/features/search/components/integration-select'),
)
const SearchSidebar = lazy(() => import('@/features/search/components/sidebar'))
const SearchResults = lazy(() => import('@/features/search/components/results'))

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/search/')({
	validateSearch: zodValidator(searchSchema),

	beforeLoad: async () => {
		const sku = getCurrentSKU() as TenantSKU

		// Redirect if the SKU is not search enabled
		if (sku && !sku.is_search_enabled) redirectHome()
	},

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		// Prefetch data for populating the sidebar and the selects

		// Prefetch the current tenant integrations (used for LogSource select)
		queryClient.ensureQueryData(integrationQueries.list(tenant))

		// Prefetch the available integrations (used for EventType select)
		queryClient.ensureQueryData(integrationQueries.available(tenant))
	},

	component: RouteComponent,
	pendingComponent: RoutePendingComponent,
	errorComponent: RouteError,
})

function RouteComponent() {
	return (
		<Section>
			<SectionTitle>Search</SectionTitle>

			<div className="flex w-full flex-col gap-4">
				<div className="flex flex-col gap-4 sm:flex-row">
					<Suspense fallback={<Skeleton className="h-10 w-full" />}>
						<IntegrationSelect />
					</Suspense>

					<Suspense fallback={<Skeleton className="h-10 w-full" />}>
						<TimeRange />
					</Suspense>
				</div>

				<QueryInput />

				<Suspense fallback={<Skeleton className="h-10 w-full" />}>
					<SearchFilters />
				</Suspense>
			</div>

			<div className="my-8 flex flex-row items-start justify-start gap-6">
				<QueryBoundary
					fallback={({ resetError }) => (
						<ErrorBoundaryComponent resetError={resetError} />
					)}
				>
					<Suspense fallback={<Skeleton className="h-full min-h-96 w-64" />}>
						<SearchSidebar />
					</Suspense>
				</QueryBoundary>

				<Suspense fallback={<Skeleton className="h-full min-h-96 w-full" />}>
					<SearchResults />
				</Suspense>
			</div>
		</Section>
	)
}

function RoutePendingComponent() {
	return (
		<Section>
			<SectionTitle>Search</SectionTitle>

			<div className="flex w-full flex-col gap-4">
				<div className="flex flex-col gap-4 sm:flex-row">
					<Skeleton className="h-10 w-full" />
					<Skeleton className="h-10 w-full" />
					<Skeleton className="h-10 w-full" />
				</div>

				<Skeleton className="h-10 w-full" />
			</div>

			<div className="my-8 flex flex-row gap-6">
				<Skeleton className="h-full w-64" />
				<Skeleton className="h-full w-full" />
			</div>
		</Section>
	)
}

function RouteError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<Section>
			<SectionTitle>Search</SectionTitle>

			<div className="flex w-full flex-col gap-4">
				<ErrorBoundaryComponent resetError={reset} />
			</div>
		</Section>
	)
}
