import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'

import { time } from '@/utils'
import { AlertOctagonIcon, Eye } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'

import { getActionStatus } from '../activity/api'
import { activityKeys, activityQueries } from '../activity/queries'
import { graylogKeys } from '../graylog/queries'
import { useCurrentTenant } from '../user/store'

import { runAsynchronousAction, runSynchronousAction } from './api'

import type { ActivityDTO } from '../activity/types'
import type { ActionData } from './types'

/**
 * Mutation keys for Actions
 */
export const actionsMutations = {
	all: ['actions'] as const,
	run: () => [...actionsMutations.all, 'run'] as const,
	runSync: () => [...actionsMutations.run(), 'sync'] as const,
}

/**
 * Custom hook to run an asynchronous action using ReactQuery
 */
export const useRunAction = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: actionsMutations.run(),
		mutationFn: (action_data: ActionData) =>
			runAsynchronousAction(tenant, action_data),

		// TODO: this is a bit of a hack, but it works for now, probably need to refactor this
		onSuccess: (data) => {
			const actionUUID: string = data?.action_uuid
			const promise = checkStatusInterval(tenant, actionUUID)

			toast.promise(promise, {
				loading: 'Running Action...',
				success: (data) => {
					// Invalidate the activity list
					queryClient.invalidateQueries({
						queryKey: activityKeys.list(tenant),
					})

					// Prefetch the activity details
					queryClient.prefetchInfiniteQuery(activityQueries.list(tenant))
					queryClient.prefetchQuery(activityQueries.detail(tenant, actionUUID))

					return {
						message: (
							<div className="flex w-full flex-row items-center justify-between gap-2">
								Action ran successfully!
								<Button
									variant="ghost"
									size="icon"
									title="View action details"
									name="view_action_details"
									asChild
								>
									<Link
										to="/activity"
										search={{ action: data?.details?.context?.action_uuid }}
									>
										<Eye className="h-5 w-5" />
									</Link>
								</Button>
							</div>
						),
					}
				},
				error: () => {
					// Invalidate the activity list
					queryClient.invalidateQueries({
						queryKey: activityKeys.list(tenant),
					})

					return (
						<div className="flex flex-row items-center justify-between gap-2">
							<AlertOctagonIcon className="h-5 w-5" />
							An error occurred while running the action.
						</div>
					)
				},
			})
		},
	})
}

/**
 * Custom hook to run a synchronous action using ReactQuery
 */
export const useRunSynchronousAction = () => {
	const tenant = useCurrentTenant() as string
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: actionsMutations.runSync(),
		mutationFn: (module_data: ActionData) =>
			runSynchronousAction(tenant, module_data),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: graylogKeys.events(tenant),
			})
		},
	})
}

/**
 * Check the status of the activity
 * @param tenant - The tenant to fetch the activity status for
 * @param action_uuid - The UUID of the activity to fetch the status for
 */
async function checkStatusInterval(
	tenant: string,
	action_uuid: string,
): Promise<ActivityDTO> {
	return new Promise((resolve, reject) => {
		const MAX_RETRIES = 10
		let retryCount = 0

		const checkInterval = setInterval(
			async () => {
				try {
					const data = await getActionStatus(tenant, action_uuid)
					const isCompleted =
						data?.summary?.status === 'success' ||
						data?.summary?.status === 'error'

					if (isCompleted) {
						clearInterval(checkInterval)
						resolve(data)
					}

					retryCount++

					if (retryCount > MAX_RETRIES) {
						clearInterval(checkInterval)
						reject(new Error('Action failed'))
					}
				} catch (error) {
					console.log(error)
					clearInterval(checkInterval)
					reject(error)
				}
			},
			time(4, 's'),
		)
	})
}
