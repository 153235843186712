import { lazy, memo, Suspense, useMemo } from 'react'

import { cn } from '@/utils'
import { closestCenter } from '@dnd-kit/collision'
import { useSortable } from '@dnd-kit/react/sortable'
import { ErrorBoundary } from '@sentry/react'
import { Columns2, Columns3, EllipsisVertical, Square } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarShortcut,
	MenubarSub,
	MenubarSubContent,
	MenubarSubTrigger,
	MenubarTrigger,
} from '@/components/ui/menubar'

import { useWidgetActions } from '../store'

import ErrorWidget from './widgets/error-widget'
import SkeletonWidget from './widgets/skeleton-widget'

import type { Widget as WidgetType } from '../types'

const AlertHistory = lazy(() => import('./widgets/alert-history'))
const NumberWidget = lazy(() => import('./widgets/number-widget'))
const TableWidget = lazy(() => import('./widgets/table-widget'))

type WidgetProps = {
	index: number
	className?: string
	widget: WidgetType
}

/**
 * Renders a widget
 * @param {number} index - The index of the widget
 * @param {string} className - The class name of the widget
 * @param {Widget} widget - The widget data to render
 */
export const Widget = ({ index, className, widget }: WidgetProps) => {
	// Get actions from store
	const { updateWidget } = useWidgetActions()

	// Init DnD Kit
	const { ref, handleRef, isDragSource } = useSortable({
		id: widget.id,
		index,
		collisionDetector: closestCenter,
		feedback: 'clone',
	})

	const handleChangeSize = (size: 1 | 2 | 3) => {
		updateWidget(widget.id, { size })
	}

	// The the widget component to render based on the widget type
	const WidgetComponent = useMemo(() => {
		switch (widget.type) {
			case 'number':
				return <NumberWidget priority={widget.data?.priority ?? 1} />
			case 'table':
				return <TableWidget />
			case 'graph':
				return <AlertHistory />
		}
	}, [widget.type])

	return (
		<div
			ref={ref}
			className={cn(
				`col-span-1 w-full gap-2 data-[dnd-placeholder]:opacity-50`,
				className,
				{
					'row-span-2': widget.type === 'graph' || widget.type === 'table',
					'shadow-lg': isDragSource,
					'sm:col-span-2': widget.data?.size === 2,
					'sm:col-span-3': widget.data?.size === 3,
				},
			)}
		>
			<div className="h-full w-full cursor-default bg-card">
				<div className="mb-2 flex h-10 flex-row items-stretch justify-stretch bg-muted p-0">
					<div
						ref={handleRef}
						className="relative flex h-full min-h-8 w-full cursor-grab items-center justify-start px-4 py-2 text-sm text-muted-foreground"
						data-testid="widget-title"
					>
						{widget.data?.name ?? 'Widget'}
					</div>
					<WidgetMenu setSize={handleChangeSize} />
				</div>

				<ErrorBoundary fallback={<ErrorWidget />}>
					<Suspense fallback={<SkeletonWidget />}>{WidgetComponent}</Suspense>
				</ErrorBoundary>
			</div>
		</div>
	)
}

type WidgetMenuProps = {
	setSize: (size: 1 | 2 | 3) => void
}

/**
 * Widget menu (e.g. for changing the size of the widget)
 * @param {Function} setSize - Function to set the size of the widget
 */
const WidgetMenu = ({ setSize }: WidgetMenuProps) => {
	return (
		<Menubar className="border-0 bg-transparent">
			<MenubarMenu>
				<MenubarTrigger asChild>
					<Button
						size="icon"
						variant="ghost"
						type="button"
						className="h-9 w-9 cursor-pointer"
					>
						<EllipsisVertical />
					</Button>
				</MenubarTrigger>
				<MenubarContent>
					<MenubarSub>
						<MenubarSubTrigger>Size</MenubarSubTrigger>
						<MenubarSubContent>
							<MenubarItem onSelect={() => setSize(3)}>
								3 columns
								<MenubarShortcut>
									<Columns3 className="h-5 w-5" />
								</MenubarShortcut>
							</MenubarItem>
							<MenubarItem onSelect={() => setSize(2)}>
								2 columns
								<MenubarShortcut>
									<Columns2 className="h-5 w-5" />
								</MenubarShortcut>
							</MenubarItem>
							<MenubarItem onSelect={() => setSize(1)}>
								1 column
								<MenubarShortcut>
									<Square className="h-5 w-5" />
								</MenubarShortcut>
							</MenubarItem>
						</MenubarSubContent>
					</MenubarSub>
				</MenubarContent>
			</MenubarMenu>
		</Menubar>
	)
}

export default memo(Widget)
