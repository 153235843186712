import type { Integration } from './types'

/**
 * Integration IDs and their friendly names
 */
export const INTEGRATION_IDS: Record<string, string> = {
	'51d5d953-c8d5-5f56-8105-71efdcf856b2': 'Graylog',
	'034b0e74-6b05-501f-8e87-9f1f050d6986': 'Microsoft Azure',
	'04cd5f6e-4125-5a6f-acd2-8dc1cd3bab2d': 'Google',
	'2be9a108-129c-5fce-8147-724b2b1b4315': 'OneLogin',
	'4c9d4a58-7ef2-562e-ad8e-ac7867fca3d1': 'Duo',
	'fa68480e-db81-57fd-9845-9fb68b8052cf': 'Okta',
	'aeffd6b7-6ada-5e98-8b66-2c791f938b60': 'Microsoft Defender',
	'eda5b806-4fa2-50d8-a78c-b1d0daf6c88e': 'Microsoft Office 365',
	'92ffcd09-9271-5431-9e96-c433acff692b': 'Microsoft Message Trace',
}

export const ONBOARDING_IDS: string[] = [
	'51d5d953-c8d5-5f56-8105-71efdcf856b2', // Graylog
]

/**
 * Get the integration id from the integration name
 * @param name - The name of the integration
 * @returns The integration id
 */
export function getIntegrationId(name: string) {
	return Object.keys(INTEGRATION_IDS).find(
		(id) => INTEGRATION_IDS[id].toLowerCase() === name.toLowerCase(),
	)
}

/**
 * Get the integration name from the integration id
 * @param id - The id of the integration
 * @returns The name of the integration
 */
export function getIntegrationName(id: string) {
	return INTEGRATION_IDS[id]
}

/**
 * Check if an integration has an onboarding process
 * @param integration_id - The id of the integration
 * @returns Whether the integration has an onboarding process
 */
export function integrationHasOnboarding(integration_id: string) {
	return ONBOARDING_IDS.includes(integration_id)
}

/**
 * Get integrations that have events enabled
 * @param integartions - The integrations to check
 * @returns The integrations that have events enabled
 */
export function getIntegrationsWithEvents(integartions: Integration[]) {
	const integrationsWithEvents = integartions.filter((integration) => {
		return integration.events_enabled
	})

	return integrationsWithEvents
}
