import { createFileRoute, redirect } from '@tanstack/react-router'

import { integrationQueries } from '@/features/integrations/queries'
import { getIntegrationId } from '@/features/integrations/utils'
import { tenantQueries } from '@/features/tenant/queries'
import { getCurrentTenant } from '@/features/user/store'

/*
	- TODO: 
	- FIRST LOGIN:
		- If SKU is for graylog, AND graylog is not setup, redirect to graylog wizard
		- If SKU is for log ingestion, and there's no integration, redirect to integrations page
	- AFTER SETUP:
		- If graylog is setup, homepage becomes /graylog
		- If log ingestion is setup, homepage becomes /home dashboard
		- If both, homepage becomes /home dashboard


	- We can use the SKU `search_enabled`, if it is false, for sure they never will
		have event ingestion

*/

export const Route = createFileRoute('/_dashboard/')({
	beforeLoad: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		// Tenant can be undefined before the query finishes
		if (tenant) {
			const tenantDetails = await queryClient.fetchQuery(
				tenantQueries.details(tenant),
			)
			const integrations = await queryClient.fetchQuery(
				integrationQueries.list(tenant),
			)

			const graylog = getIntegrationId('Graylog')
			const hasIntegrations = integrations?.length > 0
			const hasGraylog = integrations?.some(
				(integration) => integration.definition_id === graylog,
			)

			// Tenant has Log Ingestion enabled
			if (tenantDetails.sku_info.is_search_enabled) {
				// If there are no integrations, redirect to integrations page
				if (!hasIntegrations) {
					throw redirect({ to: '/integrations' })
				}

				throw redirect({ to: '/air' })
			}

			// Tenant has Graylog setup
			if (hasGraylog) {
				throw redirect({ to: '/graylog' })
			}

			// Tenant has no integrations and search disabled, means graylog wizard
			if (!hasIntegrations) {
				throw redirect({ to: '/integrations' })
			}
		}
	},
})
