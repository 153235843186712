import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import { IntegrationsList } from '@/features/integrations/components/list'
import { integrationQueries } from '@/features/integrations/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { SectionTitle } from '@/components/environment/section'
import { GridSkeleton } from '@/components/skeletons/grid-skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/integrations/')({
	loader: ({ context: { queryClient } }) => {
		const currentTenantID = getCurrentTenant() as string

		queryClient.prefetchQuery(integrationQueries.list(currentTenantID))
		queryClient.prefetchQuery(integrationQueries.available(currentTenantID))
	},

	component: IntegrationsHomePage,
	errorComponent: IntegrationsErrorPage,
	pendingComponent: IntegrationsLoadingPage,
})

function IntegrationsHomePage() {
	useTitle('Integrations')

	return (
		<>
			<SectionTitle>Integrations</SectionTitle>
			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<GridSkeleton length={10} />}>
					<IntegrationsList />
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

function IntegrationsErrorPage({ error, reset }: ErrorComponentProps) {
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}

function IntegrationsLoadingPage() {
	return <GridSkeleton length={10} />
}
