import { redirect } from '@tanstack/react-router'

/**
 * Parses the redirect URL and returns the pathname and search object.
 * @param redirect - The redirect URL.
 * @returns The pathname and search object.
 */
export const parseRedirect = (redirect: string) => {
	// Convert the redirect string to a URL object
	const redirectTo = new URL(decodeURIComponent(redirect))

	// Grab the pathname and search from the redirect URL
	const { pathname, searchParams } = redirectTo

	// Convert the search params to an object
	const searchObject = Object.fromEntries(searchParams.entries())

	// Check if values of searchObject are objects and parse them
	const parsedSearchObject = Object.entries(searchObject).reduce(
		(acc, [key, value]) => {
			if (value.includes('{')) {
				acc[key] = JSON.parse(value)
			} else {
				acc[key] = value
			}

			return acc
		},
		{} as Record<string, string>,
	)

	return { pathname, search: parsedSearchObject }
}

/**
 * Redirects to the home page.
 */
export const redirectHome = () => {
	throw redirect({ to: '/' })
}
