import { Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import AlertsFilters from '@/features/air/alerts/components/filters'
import WidgetList from '@/features/air/alerts/components/widget-list'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import { GridSkeleton } from '@/components/skeletons/grid-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

export const Route = createFileRoute('/_dashboard/air/')({
	component: RouteComponent,
})

// TODO: this is a copy/pasta from Graylog widgets, needs refactor in the future

function RouteComponent() {
	return (
		<Section>
			<SectionTitle>Air</SectionTitle>

			<Suspense fallback={<Skeleton className="mb-2 h-[70px] w-full" />}>
				<AlertsFilters />
			</Suspense>

			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<GridSkeleton length={6} />}>
					<WidgetList />
				</Suspense>
			</ErrorBoundary>
		</Section>
	)
}
