import { Feedback } from '@dnd-kit/dom'
import { DragDropProvider } from '@dnd-kit/react'

import { useWidgetActions, useWidgets } from '../store'

import Widget from './widget'

/**
 * Renders the list of widgets for the alerts page with Drag and Drop functionality
 */
export const WidgetList = () => {
	// Get widgets from store
	const widgets = useWidgets()
	const { moveWidget } = useWidgetActions()

	return (
		<DragDropProvider
			plugins={[Feedback]}
			onDragOver={({ operation, preventDefault }) => {
				preventDefault()

				const { source, target } = operation

				moveWidget(source?.id as string, target?.id as string)
			}}
		>
			<div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
				{widgets.map((widget, index) => {
					return <Widget key={widget.id} widget={widget} index={index} />
				})}
			</div>
		</DragDropProvider>
	)
}

export default WidgetList
