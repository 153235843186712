import { z } from 'zod'

export const searchSchema = z.object({
	integrations: z
		.array(
			z.object({
				connector_definition_id: z.string(),
				event_type: z.string(),
			}),
		)
		.optional(),
	connector_id: z.string().optional(),
	query: z.string().optional(),
	timeRange: z
		.object({
			start: z.string().optional(),
			end: z.string().optional(),
		})
		.optional(),
	filters: z.array(z.string()).optional(),
})

export type SearchParams = z.infer<typeof searchSchema>

export type SearchIntegrations = NonNullable<SearchParams['integrations']>

export type SearchResults = {
	hits: {
		hits: Record<string, any>[]
		max_score: number
		total: {
			value: number
			relation: string
		}
	}
	aggregations?: SearchAggs
	timeout: boolean
	took: number
}

export type SearchAggs = {
	events_over_time?: {
		buckets: {
			[key: number]: {
				key: number
				doc_count: number // Number of events in the bucket
				key_as_string: string // Date of the bucket
			}
		}
	}
	display_names?: {
		doc_count_error_upper_bound: number
		sum_other_doc_count: number
		buckets: {
			key: string
			doc_count: number
		}[]
	}
}

export type QuickSelectType = 'last' | 'next'
export type QuickSelectUnit =
	| 'second'
	| 'minute'
	| 'hour'
	| 'day'
	| 'week'
	| 'month'
	| 'year'

export type EventType = {
	connector_definition_id: string
	connector_name: string
	event_type_id: string
}

export type FieldGroup = {
	schema: {
		properties: {
			[key: string]: {
				description: string
				format: string
				type: string
				title: string
			}
		}
		required: string[]
		title: string
		type: string
	}
}
