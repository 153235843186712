import { useState } from 'react'

import { Check, Pencil } from 'lucide-react'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

import { useEditIntegration } from '../mutations'

import type { Integration } from '../types'

type IntegrationStatusCardTitleProps = {
	integration: Integration
}

export const IntegrationStatusCardTitle = ({
	integration,
}: IntegrationStatusCardTitleProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [integrationName, setIntegrationName] = useState<string>(
		integration.name,
	)

	const { mutate: editIntegration } = useEditIntegration()

	const handleEdit = () => {
		setIsEditing((prev) => !prev)
	}

	const handleSave = () => {
		editIntegration({
			integration_id: integration.id,
			integration_data: { name: integrationName },
		})

		setIsEditing(false)
	}

	return (
		<div className="flex w-full flex-row items-center justify-between gap-2">
			{isEditing ? (
				<Input
					value={integrationName}
					onChange={(e) => setIntegrationName(e.target.value)}
				/>
			) : (
				<h3 className="text-2xl font-bold leading-tight">{integrationName}</h3>
			)}
			<HasRole role={Roles.enum.SystemManager}>
				{isEditing ? (
					<Button size="icon" variant="ghost" onClick={handleSave}>
						<Check className="size-4 text-muted-foreground" />
					</Button>
				) : (
					<Button size="icon" variant="ghost" onClick={handleEdit}>
						<Pencil className="size-4 text-muted-foreground" />
					</Button>
				)}
			</HasRole>
		</div>
	)
}
