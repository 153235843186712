import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import {
	fetchContract,
	fetchContractFiles,
	fetchTenantBillingInformation,
	fetchTenantContacts,
	fetchTenantDetails,
	fetchTenantUsers,
} from './api'

export const tenantKeys = {
	all: ['tenant'] as const,
	details: (tenant: string) => [...tenantKeys.all, 'details', tenant] as const,
	users: (tenant: string, show_all: boolean = false) =>
		[...tenantKeys.all, 'users', tenant, show_all] as const,
	contacts: (tenant: string) =>
		[...tenantKeys.all, 'contacts', tenant] as const,
	contract: (tenant: string) =>
		[...tenantKeys.all, 'contract', tenant] as const,
	contractFiles: (tenant: string, type: 'msa' | 'sow') =>
		[...tenantKeys.all, 'contractFiles', tenant, type] as const,
	billing: (tenant: string) => [...tenantKeys.all, 'billing', tenant] as const,
}

export const tenantQueries = {
	details: (tenant: string) =>
		queryOptions({
			queryKey: tenantKeys.details(tenant),
			queryFn: () => fetchTenantDetails(tenant),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
		}),

	users: (tenant: string, show_all: boolean = false) =>
		queryOptions({
			queryKey: tenantKeys.users(tenant, show_all),
			queryFn: () => fetchTenantUsers(tenant, show_all),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
		}),

	contacts: (tenant: string) =>
		queryOptions({
			queryKey: tenantKeys.contacts(tenant),
			queryFn: () => fetchTenantContacts(tenant),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
		}),

	contract: (tenant: string) =>
		queryOptions({
			queryKey: tenantKeys.contract(tenant),
			queryFn: () => fetchContract(tenant),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
		}),

	contractFiles: (tenant: string, type: 'msa' | 'sow') =>
		queryOptions({
			queryKey: tenantKeys.contractFiles(tenant, type),
			queryFn: () => fetchContractFiles(tenant, type),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
		}),

	billing: (tenant: string) =>
		queryOptions({
			queryKey: tenantKeys.billing(tenant),
			queryFn: () => fetchTenantBillingInformation(tenant),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
		}),
}

/**
 * Custom hook to fetch Tenant Details
 */
export const useTenantDetails = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.details(tenant))
}

/**
 * Custom hook to fetch all Tenant Users
 * @param show_all - Whether to show all users from all tenants
 */
export const useTenantUsers = (show_all: boolean = false) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.users(tenant, show_all))
}

/**
 * Custom hook to fetch all Tenant Contacts
 */
export const useTenantContacts = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.contacts(tenant))
}

/**
 * Custom hook to fetch the tenant contract
 */
export const useTenantContract = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.contract(tenant))
}

/**
 * Custom hook to fetch the tenant contract files
 */
export const useTenantContractFiles = (type: 'msa' | 'sow') => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.contractFiles(tenant, type))
}

/**
 * Custom hook to fetch the tenant billing information
 */
export const useTenantBillingInformation = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(tenantQueries.billing(tenant))
}
